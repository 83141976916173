import React, { Component } from "react";
import {
  Container,
  Header,
  Icon,
  Message,
  Segment,
  Image,
} from "semantic-ui-react";
import {
  keycloakClientId,
  keycloakRealm,
  keycloakGuestRealm,
  keycloakUrl,
  sidebar_identities,
  keycloakRedirectBase,
} from "../../config";
import Keycloak from "keycloak-js";
import { connect } from "react-redux";
import SSO from "../../assets/images/sso-guest-login-indication.png";
import { LoadingWrapper } from "@authzsvc/common-react-components";

const silentSsoCheckUrl = `${window.location.origin}/check-sso-silent.html`;

class GuestAccountRegistrationConfirmation extends Component {
  state = {
    confirmed: false,
    guestClient: new Keycloak({
      url: keycloakUrl,
      realm: keycloakRealm,
      clientId: keycloakClientId,
    }),
  };

  async componentDidMount() {
    this.state.guestClient
      .init({
        promiseType: "native",
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: silentSsoCheckUrl,
        silentCheckSsoFallback: false,
        flow: "implicit",
      })
      .then(async (authenticated) => {
        if (authenticated) {
          this.setState({ confirmed: true });
        } else {
          // Log in the user
          this.state.guestClient.login({
            idpHint: keycloakGuestRealm,
            redirectUri: `${keycloakRedirectBase}/guest-registration/complete`,
          });
        }
      })
      .catch(() => {
        console.log("Error");
      });
  }

  render() {
    return (
      <LoadingWrapper loading={!this.state.confirmed}>
        <Segment basic>
          <Container>
            <Segment>
              <Header as={"h2"}>
                <Icon name="user outline" /> Your Guest Account is active!
              </Header>
              <Message>
                <p>
                  When you{" "}
                  <a href={sidebar_identities}>
                    <b> authenticate to CERN </b>
                  </a>
                  , select the <b>"External email - Guest access" </b>
                  option, then enter your email and the password you chose.
                  <br />
                  <br />
                  <Image src={SSO} size="big" centered />
                </p>
                <p>
                  If you encounter any issues regarding your account,{" "}
                  <b>please contact </b>
                  <a href="https://cern.service-now.com/service-portal">
                    <b>Service desk</b>
                  </a>
                </p>
              </Message>
            </Segment>
          </Container>
        </Segment>
      </LoadingWrapper>
    );
  }
}
export default connect(
  (state) => ({}),
  {}
)(GuestAccountRegistrationConfirmation);
